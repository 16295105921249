import puzzlesWithSolutions from './puzzles'

export default {
  /* Meta Data */
  meta_title: 'Get Involved — PennyLane',
  meta_description:
    'Collaborate with us to share your innovative research with the quantum community, accelerate breakthroughs, and shape the future of quantum computing.',
  meta_image:
    'https://assets.cloud.pennylane.ai/pennylane_website/pages/get_involved/open_graph.png',

  /* Hero */
  hero: {
    title: 'Collaborate to innovate in quantum computing.',
    description:
      'Share your research to shape the future of quantum! Together, we can accelerate progress and achieve breakthroughs faster.',
    cta: {
      label: 'Showcase your research',
      link: '/qml/demos_submission/',
    },
    highlights: {
      title: 'Highlights',
      items: [
        {
          title:
            `Obtain significant speedups by using quantum just-in-time compilation and PennyLane`,
          image:
       'https://assets.cloud.pennylane.ai/pennylane_website/pages/get_involved/thumbs/lightning_thumbnail.png',
          link: '/blog/2024/11/quantitative-advantage-of-hybrid-compilation-with-pennylane/',
          date: 'Nov 12, 2024',
        },
        {
          title:
            `Master the recently developed technique for constant-depth preparation of MPS with adaptive quantum circuits`,
          image:
       'https://assets.cloud.pennylane.ai/pennylane_website/pages/get_involved/thumbs/papers_thumbnail.png',
          link: '/qml/demos/tutorial_constant_depth_mps_prep/',
          date: 'Oct 09, 2024',
        },
        {
          title:
            `Learn to apply post-variational quantum neural networks from CQT and Oxford researchers`,
          image:
       'https://assets.cloud.pennylane.ai/pennylane_website/pages/get_involved/thumbs/demos_thumbnail.png',
          link: '/qml/demos/tutorial_post-variational_quantum_neural_networks/',
          date: 'Oct 07, 2024',
        },
        {
          title:
            `Use BlueQubit's CPU devices with up to 33 qubits to run PennyLane simulations for free`,
          image:
       'https://assets.cloud.pennylane.ai/pennylane_website/pages/get_involved/thumbs/megaphone_thumbnail.png',
          link: '/qml/demos/tutorial_bluequbit/',
          date: 'Sep 24, 2024',
        },
      ],
    },
  },

  /* Info Sections */
  info_section_one: {
    title: 'Demonstrate your research',
    image:
      'https://assets.cloud.pennylane.ai/pennylane_website/pages/get_involved/community_demos.png',
    description:
      'Collaborate with us to showcase your work, inspire others, and accelerate progress in the quantum community! Contribute a demo and highlight your innovations to fellow researchers, whether you are using PennyLane or another tool.',
    cta: {
      label: 'Submit a demo',
      link: '/qml/demos_submission',
    },
  },
  info_section_two: {
    title: 'Optimize PennyLane for your research',
    image:
      'https://assets.cloud.pennylane.ai/pennylane_website/pages/get_involved/community_connection.png',
    description:
      'Researchers around the world are joining us in developing a quantum programming framework designed to meet all your research needs. Start contributing on GitHub, where development is happening publicly!',
    cta: {
      label: 'Contribute to PennyLane',
      link: 'https://docs.pennylane.ai/en/stable/development/guide/contributing.html',
    },
  },

  /* Puzzles Section */
  puzzle_section: {
    title: 'Quantum Puzzles',
    description_markdown: `Your dose of quantum joy and the perfect icebreaker for your next talk. \n \n Want to use these in your next presentation or talk?`,
    cta: {
      label: 'Download the puzzles here',
      link: 'https://drive.google.com/drive/folders/1545zM9FemkzronxaBG2xJqG_MUt2RFAe?usp=sharing',
    },
    policy_markdown: `These puzzles are licensed under [CC BY-ND 4.0](http://creativecommons.org/licenses/by-nd/4.0/).`,
    /* Quantum Puzzles */
    puzzles: {
      input_label: 'Your answer',
      submit_label: 'Check answer',
      next_slide_label: 'Next puzzle',
      correct_message: 'Correct!',
      incorrect_message: 'Incorrect. Try again!',
      solution_label: 'See solution',
      items: puzzlesWithSolutions,
    },
  },

  /* Community */
  community: {
    background_image:
      'https://assets.cloud.pennylane.ai/pennylane_website/backgrounds/confetti_magenta.png',
    background_colour: '#7F007F',
    title: 'Connect with the Community',
    description: `Share your expert insights and discuss your research.`,
    cards: [
      {
        title: 'Forum',
        description: 'Have a technical question? Join our discussion forum.',
        cta: {
          label: 'Ask a question',
          link: 'https://discuss.pennylane.ai/',
        },
        image:
          'https://assets.cloud.pennylane.ai/pennylane_website/icons/forum_illustration.png',
      },
      {
        title: 'Slack',
        description: 'Engage with the global quantum community.',
        cta: {
          label: 'Connect now',
          link: 'https://join.slack.com/t/xanadu-quantum/shared_invite/zt-1i8v8v49d-S76QxXm3OKCm9g0bvWvDpg',
        },
        image:
          'https://assets.cloud.pennylane.ai/pennylane_website/icons/slack_illustration.png',
      },
      {
        title: 'Discord',
        description: 'Chat with PennyLane users and contributors.',
        cta: {
          label: 'Join the conversation',
          link: 'https://discord.com/invite/gnySM3nrN3',
        },
        image:
          'https://assets.cloud.pennylane.ai/pennylane_website/icons/discord_illustration.png',
      },
    ],
  },

  /* Contact / Suggestions Form */
  contact: {
    title: 'Collaborate with us!',
    description:
      'Got a demo or blog post idea to showcase impactful work? Connect with the PennyLane Team and make it happen!',
    form: {
      /*  Field labels and placeholders */
      first_name: 'First name',
      last_name: 'Last name',
      email: 'Email',
      feedback_message: 'Share your idea (required)',
      submit: 'Submit',
    },
    privacy_markdown: `By submitting this form, you're agreeing to our [privacy policy](/privacy).`,
    success_title: 'Thank you!',
    success_description: 'A PennyLane team member will be in touch with you shortly.',
    error_message: 'Something went wrong. Please try again later.',
  },
}
