import { useState } from 'react'
import useForm from '../../hooks/useForm'
import { Input } from 'component-library'
import content from '../../content/get-involved'

import './GettingInvolvedContactSection.scss'
import FormLayout from '../FormLayouts/FormLayout/FormLayout'

type FormValues = {
  firstName: string
  lastName: string
  email: string
  feedback: string
}

const initialValues: FormValues = {
  firstName: '',
  lastName: '',
  email: '',
  feedback: '',
}

const {
  title,
  description,
  form,
  privacy_markdown,
  success_title,
  success_description,
  error_message,
} = content.contact

const GOOGLE_SHEET_URL =
  'https://script.google.com/macros/s/AKfycbw89_KKm849qIfG6HHfVleE8cnGV4ROAbJVCF7If5FMbOYPIf_E3FHWjnYwiH3GBvro8Q/exec'

const GetInvolvedContactSection = () => {
  const FORM_NAME = 'get-involved-contact'
  const { values, handleChange, handleSubmit } = useForm<FormValues>({
    initialValues,
  })
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [submittingForm, setSubmittingForm] = useState(false)
  const [showFormSubmissionError, setShowFormSubmissionError] = useState(false)

  const onSubmit = async (values: FormValues) => {
    const formData = new FormData()
    formData.append('firstName', values.firstName)
    formData.append('lastName', values.lastName)
    formData.append('email', values.email)
    formData.append('feedback', values.feedback)

    setShowFormSubmissionError(false)
    setSubmittingForm(true)

    const response = await fetch(GOOGLE_SHEET_URL, {
      method: 'POST',
      body: formData,
    })
    if (response && response.status === 200) {
      setIsSubmitted(true)
    } else {
      setShowFormSubmissionError(true)
    }
    setSubmittingForm(false)
  }

  return (
    <FormLayout
      id="get-in-touch"
      image="https://assets.cloud.pennylane.ai/pennylane_website/pages/get_involved/community_collaboration.png"
      title={title}
      description={description}
      formName={FORM_NAME}
      isSubmitted={isSubmitted}
      isSubmitting={submittingForm}
      isSubmissionError={showFormSubmissionError}
      onSubmit={handleSubmit(onSubmit)}
      className="GettingInvolvedContactSection"
      footnoteMarkdown={privacy_markdown}
      submissionErrorText={error_message}
      formSuccessTitle={success_title}
      formSuccessText={success_description}
    >
      <div className="grid grid-cols-2 gap-2 w-full">
        <Input
          className="col-span-2 md:col-span-1"
          type="text"
          formName={FORM_NAME}
          fieldName="firstName"
          fieldLabel={form.first_name}
          placeholder={form.first_name}
          fieldLabelHidden
          updateValue={(value) => handleChange('firstName', value)}
          value={values?.firstName || initialValues.firstName}
        />
        <Input
          className="col-span-2 md:col-span-1"
          type="text"
          formName={FORM_NAME}
          fieldName="lastName"
          fieldLabel={form.last_name}
          placeholder={form.last_name}
          fieldLabelHidden
          updateValue={(value) => handleChange('lastName', value)}
          value={values?.lastName || initialValues.lastName}
        />
        <Input
          className="col-span-2"
          type="email"
          formName={FORM_NAME}
          fieldName="email"
          fieldLabel={form.email}
          placeholder={form.email}
          fieldLabelHidden
          updateValue={(value) => handleChange('email', value)}
          value={values?.email || initialValues.email}
        />
        <Input
          className="col-span-2"
          type="textarea"
          formName={FORM_NAME}
          fieldName="feedback"
          required
          fieldLabel={form.feedback_message}
          placeholder={form.feedback_message}
          fieldLabelHidden
          updateValue={(value) => handleChange('feedback', value)}
          value={values?.feedback || initialValues.feedback}
          draggable={false}
          minHeight="87px"
          maxHeight="87px"
        />
      </div>
    </FormLayout>
  )
}

export default GetInvolvedContactSection
