import CardLink from '../CardLink/CardLink'
import ReactMarkdown from 'react-markdown'
import './TripleCardSection.scss'

interface ITripleCardSectionProps {
  title: string
  description: string
  cards: {
    title: string
    description: string
    cta: { label: string; link: string }
    image: string
  }[]
  background_image: string
  background_colour: string
}

const TripleCardSection = (props: ITripleCardSectionProps) => {
  const { title, description, cards, background_image, background_colour } =
    props

  if (!cards) return null
  return (
    <section
      className="TripleCardSection"
      style={{
        backgroundImage: `url(${background_image})`,
        backgroundColor: `${background_colour}`,
      }}
    >
      <div className="TripleCardSection__container section-lg">
        <div className="TripleCardSection__info">
          <h2>{title}</h2>
          <ReactMarkdown
            className="TripleCardSection__info__description"
            allowedElements={['p', 'a']}
          >
            {description}
          </ReactMarkdown>
        </div>
        <ul className="TripleCardSection__cards">
          {cards?.map((card, index) => (
            <li key={`community-${card.title}-${index}`}>
              <CardLink
                icon={card.image}
                title={card.title}
                description={card.description}
                className="TripleCardSection__card"
                linkText={card.cta.label}
                link={card.cta.link}
              />
            </li>
          ))}
        </ul>
      </div>
    </section>
  )
}

export default TripleCardSection
