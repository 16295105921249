import { PageProps } from 'gatsby'
import { spotlights } from 'shared-content'
import content from '../../content/get-involved'
import GettingInvolvedContactSection from '../../components/GettingInvolvedContactSection/GettingInvolvedContactSection'
import GettingInvolvedHero from '../../components/GettingInvolvedHero/GettingInvolvedHero'
import GettingInvolvedPuzzles from '../../components/GettingInvolvedPuzzles/GettingInvolvedPuzzles'
import ResearchDemos from '../../components/ResearchDemos/ResearchDemos'
import Seo from '../../components/seo'
import SpotlightSection from '../../components/SpotlightSection/SpotlightSection'
import SubSectionLayout from '../../components/SubSectionLayout/SubSectionLayout'
import TripleCardSection from '../../components/TripleCardSection/TripleCardSection'

const { meta_title, meta_description, meta_image, community } = content
const spotlight = spotlights.get_involved_page

const { info_section_one, info_section_two } = content

type Props = PageProps<
  undefined,
  {
    demos: Queries.GetFeaturedResearchDemosQuery['allMarkdownRemark']['nodes']
  }
>

export default function GetInvolvedPage({ pageContext }: Props) {
  return (
    <div className="GetInvolvedPage">
      <GettingInvolvedHero />
      {!spotlight.hidden && (
        <SpotlightSection announcements={spotlight.announcements} />
      )}
      <section>
        <SubSectionLayout
          key={`getting-involved-info-section-${info_section_one.title}`}
          title={content.info_section_one.title}
          image={info_section_one.image}
          description={info_section_one.description}
          links={[
            {
              link: info_section_one.cta.link,
              link_text: info_section_one.cta.label,
            },
          ]}
          image_right_aligned={false}
        />
      </section>
      <GettingInvolvedContactSection />
      <ResearchDemos featuredDemos={pageContext.demos} />
      <section>
        <SubSectionLayout
          key={`getting-involved-info-section-${info_section_two.title}`}
          title={content.info_section_two.title}
          image={info_section_two.image}
          description={info_section_two.description}
          links={[
            {
              link: info_section_two.cta.link,
              link_text: info_section_two.cta.label,
            },
          ]}
          image_right_aligned
        />
      </section>
      <TripleCardSection
        title={community.title}
        description={community.description}
        cards={community.cards}
        background_image={community.background_image}
        background_colour={community.background_colour}
      />
      <GettingInvolvedPuzzles />
    </div>
  )
}

export const Head = () => (
  <Seo title={meta_title} description={meta_description} image={meta_image} />
)
