/**
 * Checks if an array has a length greater than 0
 */
export const hasLength = (array: unknown[]): boolean => {
  return Array.isArray(array) && array.length > 0
}

/**
 * Shuffles array and returns a new array
 * --------------------------------------
 * uses Generics to ensure that the array returned is the same 
    type as the array passed in using inference or explicit declaration
 * uses the Fisher-Yates/Knuth shuffle algorithm
 */
export const shuffleArray = <T>(array: T[]): T[] => {
  const newArray = [...array]
  for (let i = newArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[newArray[i], newArray[j]] = [newArray[j], newArray[i]]
  }
  return newArray
}
