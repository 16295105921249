import { useEffect, useMemo, useRef, useState } from 'react'
import Slider, { Settings } from 'react-slick'
import { Tooltip } from 'react-tooltip'
import { slugifyString } from 'shared-utilities'
import { shuffleArray } from '../../utils/array_helpers'
import content from '../../content/get-involved'
import { Puzzle as TPuzzle } from '../../content/puzzles'
import './GettingInvolvedSlider.scss'

const { puzzles } = content.puzzle_section

export default function GettingInvolvedPuzzleSlider() {
  const slickSettings: Settings = {
    dots: false,
    infinite: true,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    accessibility: true,
  }

  /* State */
  const slickRef = useRef<Slider>(null)
  const shuffledPuzzles = useMemo(() => shuffleArray(puzzles.items), [])

  /* Handlers */
  const handleNext = () => {
    slickRef?.current?.slickNext()
  }

  const handlePrev = () => {
    slickRef.current?.slickPrev()
  }

  return (
    <div className="GettingInvolvedSlider__container">
      <button
        aria-label="Previous puzzle"
        onClick={handlePrev}
        className="GettingInvolvedSlider__button GettingInvolvedSlider__button--prev"
      >
        <i className="bx bx-chevron-left"></i>
      </button>
      <div className="GettingInvolvedSlider">
        <Slider {...slickSettings} ref={slickRef}>
          {shuffledPuzzles.map((puzzle, i) => (
            <Puzzle
              key={`puzzle-${i}`}
              image={puzzle.image}
              alt={puzzle.alt}
              solution={puzzle.solution}
              onNext={handleNext}
            />
          ))}
        </Slider>
      </div>
      <button
        aria-label="Next puzzle"
        onClick={handleNext}
        className="GettingInvolvedSlider__button GettingInvolvedSlider__button--next"
      >
        <i className="bx bx-chevron-right"></i>
      </button>
    </div>
  )
}

type PuzzleProps = TPuzzle & {
  onNext: () => void
}

export const Puzzle = ({ image, alt, solution, onNext }: PuzzleProps) => {
  /* STATE */
  const [isCorrect, setIsCorrect] = useState(false)
  const [attempts, setAttempts] = useState(0)
  const [isSubmissionTooltipVisible, setIsSubmissionTooltipVisible] =
    useState(false)
  const [userInput, setUserInput] = useState('')

  /* Handlers */
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const isMatch = slugifyString(userInput) === slugifyString(solution)
    if (isMatch) {
      setIsCorrect(true)
    }
    if (!isMatch) {
      setAttempts(attempts + 1)
    }
  }

  const handleNext = () => {
    setIsSubmissionTooltipVisible(false)
    onNext()
  }

  /* Effects */
  useEffect(() => {
    const timing = isCorrect ? 5000 : 2000

    if ((!isCorrect && attempts > 0) || isCorrect) {
      setIsSubmissionTooltipVisible(true)
      setTimeout(() => {
        setIsSubmissionTooltipVisible(false)
      }, timing)
    }
  }, [attempts, isCorrect])

  const correctOrIncorrect = isCorrect
    ? puzzles.correct_message
    : puzzles.incorrect_message
  const inputBorderClass = `GettingInvolvedPuzzle__input${
    attempts > 0 && !isCorrect && isSubmissionTooltipVisible
      ? '--incorrect'
      : isCorrect
      ? '--correct'
      : ''
  }`
  const solutionTooltipStyles = {
    backgroundColor: isCorrect ? '#008000' : '#E00000',
  }

  return (
    <div
      className="GettingInvolvedPuzzle"
      data-testid="getting-involved-puzzle"
    >
      <div className="GettingInvolvedPuzzle__container">
        <form
          id={`puzzle-form-${solution}`}
          className="GettingInvolvedPuzzle__form"
          onSubmit={onSubmit}
        >
          <img src={image} alt={alt} />
          <label className="sr-only" htmlFor={`puzzle-input-${solution}`}>
            {puzzles.input_label}
          </label>
          <Tooltip
            id={`puzzle-input-tooltip-${solution}`}
            isOpen={isSubmissionTooltipVisible}
            style={solutionTooltipStyles}
          >
            <p>{correctOrIncorrect}</p>
          </Tooltip>
          <div className={`GettingInvolvedPuzzle__input ${inputBorderClass}`}>
            <input
              data-testid="getting-involved-puzzle-input"
              id={`puzzle-input-${solution}`}
              type="text"
              placeholder={puzzles.input_label}
              value={userInput}
              onChange={(e) => setUserInput(e.target.value)}
              data-tooltip-content={correctOrIncorrect}
              data-tooltip-place="top"
              data-tooltip-id={`puzzle-input-tooltip-${solution}`}
              disabled={isCorrect}
              autoComplete="off"
            />
          </div>

          {/* Buttons */}
          {!isCorrect && (
            <button
              data-testid="getting-involved-puzzle-submit-button"
              className="GettingInvolvedPuzzle__button GettingInvolvedPuzzle__button--submit"
              disabled={isCorrect || !userInput}
            >
              {puzzles.submit_label}
            </button>
          )}
          {isCorrect && (
            <button
              data-testid="getting-involved-puzzle-next-button"
              className="GettingInvolvedPuzzle__button GettingInvolvedPuzzle__button--next"
              onClick={handleNext}
            >
              {puzzles.next_slide_label}
            </button>
          )}

          {/* Solution Tooltip */}
          <div className="GettingInvolvedPuzzle__solution">
            <button
              type="button"
              role="tooltip"
              data-tooltip-id={solution}
              data-tooltip-content={solution}
              data-tooltip-place="bottom"
              data-testid="getting-involved-puzzle-solution-tooltip"
            >
              <span>{puzzles.solution_label}</span>
            </button>
          </div>
          <Tooltip id={solution} content={solution} closeOnEsc openOnClick />
        </form>
      </div>
    </div>
  )
}
